<template>
  <v-dialog v-model="isOpen" transition="slide-y-reverse-transition">
    <v-card
      class="m-auto"
      :class="$attrs.class"
      :data-cy-modal="id"
      :prepend-icon="icon"
      :width="width"
    >
      <slot name="title">
        <v-card-title v-if="title">
          {{ title }}
        </v-card-title>
      </slot>
      <p v-if="subtitle" class="text-subtle px-4">
        {{ subtitle }}
      </p>

      <component :is="bodyComponent" @submit="onSubmit">
        <v-card-text v-if="$slots.default">
          <slot />
        </v-card-text>

        <v-divider class="mt-6 border !opacity-100" />
        <slot name="actions">
          <v-card-actions v-if="actions" class="!flex justify-end !p-4">
            <div v-if="$slots.secondaryActions" class="flex-auto">
              <slot name="secondaryActions" />
            </div>

            <v-btn :disabled="submitting" size="large" @click="onCancel">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="submitting"
              size="large"
              type="submit"
              variant="elevated"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </slot>
      </component>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { useActiveModal } from "../composables/modal-active-state.store";
import type { AppModalId } from "../modal.registry";

defineOptions({
  inheritAttrs: false,
});

const properties = withDefaults(
  defineProps<{
    title?: string;
    subtitle?: string;
    icon?: string;
    width?: number;
    form?: boolean;
    actions?: boolean;
    submitting?: boolean;
  }>(),
  {
    title: undefined,
    subtitle: undefined,
    icon: undefined,
    width: 600,
    form: true,
    actions: true,
  },
);

const emit = defineEmits<{
  (event: "submit"): void;
}>();

const { id } = useActiveModal();
const { close } = useModal(id.value as AppModalId);

const onCancel = () => close();
const onSubmit = () => emit("submit");

const isOpen = computed({
  get() {
    return true;
  },
  set(value) {
    if (value) return;
    close();
  },
});

const AppForm = resolveComponent("app-form");
const bodyComponent = computed(() => (properties.form ? AppForm : "div"));
</script>
